<template>
  <div :class="['module_title', size == 'medium' ? 'medium' : '' ]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "small"
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>