<template>
  <div class="verify_popup">
    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      center
      top="25vh"
      :close-on-click-modal="false"
      :custom-class="customClass"
      :show-close="false"
    >
      <h3 class="label">请输入账号密码</h3>
      <el-input class="login_input" v-model="username" placeholder="请输入账号" />
      <el-input class="login_input" v-model="password" placeholder="请输入密码" />
      <span slot="footer" class="dialog-footer">
        <el-button class="login_btn" type="primary" @click="login">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isRelogin } from "@/libs/request";
import { setToken, getToken } from "@/libs/auth";
import { Login } from "@/apis/login";

export default {
  name: "VerifyPopup",
  data() {
    return {
      isRelogin,
      customClass: "custom-dialog",
      dialogVisible: false,
      password: undefined,
      username: undefined,
    };
  },
  methods: {
    // 验证token是否有
    getToken() {
      const token = getToken();
      if (!token) {
        this.dialogVisible = true;
      } else {
        this.dialogVisible = false;
      }
    },
    login() {
      Login({ username: this.username, password: this.password })
        .then((res) => {
          if (res.code == 200 && res.token) {
            this.$message.success(res.msg);
            this.dialogVisible = false;
            isRelogin.show = false;
            const userInfo = { username: this.username }
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            setToken(res.token);
            
            window.location.reload(); //登录刷新一下页面
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getToken();
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.password = undefined;
      }
    },
    "isRelogin.show": {
      handler(val) {
        if (val) {
          this.dialogVisible = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
